/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import widget from '../../index';
import iconTryAgain from '../../img/arraw-try-again.png';
import iconError from '../../img/icon-error.png';
import iconSuccess from '../../img/icon-success.png';
import MainCards from '../MainCards/MainCards';
import QrCard from '../QrCard/QrCard';
import SelphiWidget from '../SelphiWidget/SelphiWidget';
import './ResultCard.css';

function ResultCard({
  selfie, selfieDocument, qrCode, type, isAuthenticate, generateRequest,
}) {
  let message;
  let msgButton = 'Try Again';
  const qrImage = `data:image/png;base64,${qrCode}`;

  if (type === 'generate') {
    if (isAuthenticate) {
      message = 'The person in the selfie and on the document chip are the same';
      msgButton = 'generate qr code';
    } else {
      message = 'The person in the selfie and on the document chip are not the same';
    }
  } else if (isAuthenticate) {
    message = 'The person in the selfie and on the QR are the same';
    msgButton = 'finish';
  } else {
    message = 'The person in the selfie and on the QR are not the same';
  }

  function handleOnClick() {
    if (type === 'generate' && !isAuthenticate) widget.render(<SelphiWidget generateRequest={generateRequest} generateFlow />);

    if (type === 'generate' && isAuthenticate) widget.render(<QrCard qrCode={qrImage} />);

    if (type === 'authenticate' && !isAuthenticate) widget.render(<SelphiWidget qrCode={qrCode} generateFlow={false} />);

    if (type === 'authenticate' && isAuthenticate) {
      widget.render(<MainCards />);
      document.querySelector('.btnStopProcess').classList.add('hidden');
    }
  }

  if (type === 'authenticate' && isAuthenticate) document.querySelector('.btnStopProcess').classList.add('hidden');

  return (
    <div className="container-center">
      <div className="result-main">
        <h1>{type === 'generate' ? 'QR Generator' : 'User Verification'}</h1>
        <div className="result-card">
          <h2>{type === 'generate' ? 'Face Detected' : 'Face Verification'}</h2>
          <div className="result-block-image">
            <div>
              <img src={selfie} alt="selfie" className="selphi-image" />
              <p>Selfie taken</p>
            </div>
            <img src={isAuthenticate ? iconSuccess : iconError} className="icon-error" alt="icon error" />
            <div>
              <img alt={type === 'generate' ? 'selfie document' : 'QR image'} src={type === 'generate' ? selfieDocument : qrImage} className={type === 'authenticate' ? 'selphi-image border-authentication' : 'selphi-image'} />
              <p>{type === 'generate' ? 'Photo document' : 'QR Code'}</p>
            </div>
          </div>
          <p className={isAuthenticate ? 'color-green result-message' : 'color-red result-message'}>{message}</p>
          <button type="submit" onClick={() => handleOnClick()} className={type === 'generate' ? 'background-button-generate' : 'background-button-authenticate'}>
            {!isAuthenticate && <img src={iconTryAgain} alt="try again icon" />}
            {msgButton}
          </button>
        </div>
      </div>
    </div>
  );
}

ResultCard.propTypes = {
  selfie: PropTypes.string,
  selfieDocument: PropTypes.string,
  qrCode: PropTypes.string,
  type: PropTypes.string,
  isAuthenticate: PropTypes.bool,
  generateRequest: PropTypes.objectOf(),
};

ResultCard.defaultProps = {
  selfie: '',
  selfieDocument: '',
  qrCode: '',
  type: 'generate',
  isAuthenticate: false,
  generateRequest: {

  },
};

export default ResultCard;
