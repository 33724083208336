/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import SelphiWidget from '../SelphiWidget/SelphiWidget';
import widget from '../../index';
import iconClip from '../../img/icon-clip.png';
import iconPlus from '../../img/icon-plus.png';
import iconTakeSelfie from '../../img/icon-take-selfie.png';
import iconTakeSelfieGrey from '../../img/icon-take-selfie-grey.png';
import './InsertQr.css';

function InsertQr() {
  const [isDisabledCapture, setIsDisabledCapture] = useState(true);
  const [qrCode, setQrCode] = useState('');
  const [fileText, setFileText] = useState('');

  function handlerFileQr(event) {
    const file = event.target.files[0];

    function blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    }

    if (file !== null && file !== undefined) {
      setFileText(file.name);
      blobToBase64(file).then((result) => {
        setQrCode(result.substring(22));
        setIsDisabledCapture(false);
      });
    }
  }

  return (
    <div className="div-widget-base m-3">
      <h2>Choose image to decode</h2>
      <input className="form-control" type="file" id="ipt-file" onChange={(e) => handlerFileQr(e)} accept="image/png,image/jpeg,image/bmp,image/gif" />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="ipt-file">
        <img src={iconPlus} alt="input qr file" />
        Upload here your QR
      </label>
      {fileText !== '' && (
      <p>
        <img src={iconClip} alt="clip" />
        {' '}
        {fileText}
      </p>
      )}
      <button type="submit" id="btn-capture-selfie" onClick={() => widget.render(<SelphiWidget qrCode={qrCode} generateFlow={false} />)} disabled={isDisabledCapture}>
        <img src={isDisabledCapture ? iconTakeSelfieGrey : iconTakeSelfie} alt="take a selfie" />
        Take a selfie
      </button>
    </div>
  );
}

export default InsertQr;
