import React from 'react';
import logoFacephiBlanco from '../../img/logo-facephi-blanco.png';
import './Header.css';

function Header() {
  return (
    <div className="div-header">
      <header>
        <div className="container d-flex align-items-center justify-content-center">
          <a href="https://www.facephi.com/" title="FacePhi" target="_blank" rel="noreferrer"><img src={logoFacephiBlanco} alt="FacePhi" /></a>
        </div>
      </header>
    </div>
  );
}

export default Header;
