import React from 'react';
import PropTypes from 'prop-types';
import arrowDown from '../../img/arrow-down.png';
import './QrCard.css';

function QrCard({ qrCode }) {
  return (
    <div className="div-qr">
      <h2 className="title">QR Generator</h2>
      <div className="div-qr-card">
        <h2 className="subtitle">QR Code</h2>
        <img className="img-qr-code" src={qrCode} alt="qr code" />
        <a download="qrCode.png" href={qrCode}>
          <button type="button">
            <img src={arrowDown} alt="arrow down" />
            Download qr code
          </button>
        </a>
      </div>
    </div>
  );
}

QrCard.propTypes = {
  qrCode: PropTypes.string,
};

QrCard.defaultProps = {
  qrCode: '',
};

export default QrCard;
