/* eslint-disable import/no-cycle */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import reportWebVitals from './reportWebVitals';
import MainCards from './components/MainCards/MainCards';

const widget = ReactDOM.createRoot(document.getElementById('widget'));
widget.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <MainCards />,
);

const header = ReactDOM.createRoot(document.getElementById('header'));
header.render(
  <Header />,
);

const footer = ReactDOM.createRoot(document.getElementById('footer'));
footer.render(
  <Footer />,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default widget;
