import React from 'react';
import PropTypes from 'prop-types';
import iconTool from '../../img/icon-tool.png';
import './ErrorComponent.css';

function ErrorComponent({ message }) {
  return (
    <div className="div-error">
      <img src={iconTool} alt="Tool" />
      <h2>{message}</h2>
      <h2 className="text-try-again">Try again later</h2>
    </div>
  );
}

ErrorComponent.propTypes = {
  message: PropTypes.string,
};

ErrorComponent.defaultProps = {
  message: 'Failed to connect to QR authenticator service.',
};

export default ErrorComponent;
