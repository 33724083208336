/* eslint-disable import/no-cycle */
import React from 'react';
import iconGenerate from '../../img/icon-generate.png';
import iconAuthenticate from '../../img/icon-authenticate.png';
import './MainCards.css';
import widget from '../../index';
import SelphIdWidget from '../SelphIDWidget/SelphIDWidget';
import InsertQr from '../InsertQr/InsertQr';

function MainCards() {
  function showStopBtn() {
    document.querySelector('.btnStopProcess').classList.remove('hidden');
  }

  function handleClickGenerate() {
    widget.render(<SelphIdWidget />);
    showStopBtn();
  }

  function handleClickAuthenticate() {
    widget.render(<InsertQr />);
    showStopBtn();
  }

  return (
    <div className="div-main">
      <div className="div-generate">
        <img src={iconGenerate} alt="Generate Icon" />
        <h2>QR Generator</h2>
        <p>A solution that generates a QR with all the data from an ID document</p>
        <button type="button" onClick={handleClickGenerate} id="btnStartGenerate">Generate QR Code</button>
      </div>
      <div className="div-authenticate">
        <img src={iconAuthenticate} alt="Authenticate Icon" />
        <h2>QR Authenticator</h2>
        <p>A solution that allows user identity verification through biometric technology</p>
        <button type="button" onClick={handleClickAuthenticate} id="btnStartAuthentication">Authenticate With QR Code</button>
      </div>
    </div>
  );
}

export default MainCards;
