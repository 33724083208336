import React from 'react';
import loadingImage from '../../img/loading-image.png';
import './Loading.css';

function Loading() {
  return (
    <div className="div-loading">
      <img src={loadingImage} alt="Loading" />
    </div>
  );
}

export default Loading;
