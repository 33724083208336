/* eslint-disable import/no-cycle */
import React, {
  createRef, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-relative-packages
import { FPhi } from '../../scripts/selphi/selphi-widget-web.min';
import widget from '../../index';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import Loading from '../Loading/Loading';
import ResultCard from '../ResultCard/ResultCard';
import './SelphiWidget.css';

// Create lambda to render the component
function SelphiWidget({ qrCode, generateFlow, generateRequest }) {
  // Custom Code
  let facialExtractor;

  function authenticate() {
    widget.render(<Loading />);
    const dataPetitionAuth = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        qrBase64: qrCode,
        faceBase64: facialExtractor,
      }),
    };
    const urlPetitionQr = `${process.env.REACT_APP_URL_BASE_AUTHENTICATE}/authenticate`;
    fetch(urlPetitionQr, dataPetitionAuth)
      .then((response) => response.json())
      .then((data) => {
        if (!data.status) {
          const selfieImage = `data:image/png;base64,${facialExtractor}`;
          widget.render(<ResultCard selfie={selfieImage} qrCode={qrCode} type="authenticate" isAuthenticate={data.isAuthenticate} />);
        } else {
          widget.render(<ErrorComponent message={data.detail} />);
        }
      })
      .catch(() => {
        widget.render(<ErrorComponent />);
      });
  }

  function enableGenerateQr() {
    widget.render(<Loading />);
    // eslint-disable-next-line no-param-reassign
    generateRequest.facialImage = facialExtractor;
    const dataPetitionQr = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': 'http://localhost:3000',
      },
      body: JSON.stringify({ generateRequest }).slice(19),
    };
    const urlPetitionQr = `${process.env.REACT_APP_URL_BASE_GENERATE}/generate`;
    fetch(urlPetitionQr, dataPetitionQr).then((response) => response.json())
      .then((data) => {
        const facialDocument = `data:image/png;base64,${generateRequest.facialDocument}`;
        const selfieImage = `data:image/png;base64,${facialExtractor}`;

        widget.render(<ResultCard qrCode={data.qrBase64} generateRequest={generateRequest} selfie={selfieImage} selfieDocument={facialDocument} type="generate" isAuthenticate={!!data.qrBase64} />);
      })
      .catch(() => {
        widget.render(<ErrorComponent message="Failed to connect to QR generator service." />);
      });
  }

  // Widget Camera Resolutions
  const FPhiCameraResolutions = {
    res480p: { title: '640x480', width: 640, height: 480 },
    res600p: { title: '800x600', width: 800, height: 600 },
    res768p: { title: '1024x768', width: 1024, height: 768 },
    res720p: { title: '1280x720 (720p)', width: 1280, height: 720 },
    res1080p: { title: '1920x1080 (1080p)', width: 1920, height: 1080 },
  };

  // Widget General Options
  const [widgetLivenessMode] = useState(FPhi.Selphi.LivenessMode.Passive);
  const [widgetInteractible] = useState(true);
  const [widgetTutorial] = useState(false);
  const [widgetStabilizationStage] = useState(false);
  const [widgetVideoRecord] = useState(false);
  // const [widgetFaceTracking, setWidgetFaceTracking] = useState(false);

  // Widget Debug Options
  const [widgetShowLog] = useState(false);
  const [widgetDebugMode] = useState(false);

  // Widget Camera Options
  const [widgetCameraType] = useState(FPhi.Selphi.CameraType.Front);
  const [widgetCameraWidth] = useState(FPhiCameraResolutions.res720p.width);
  const [widgetCameraHeight] = useState(FPhiCameraResolutions.res720p.height);

  // Create references
  const widgetRef = createRef();
  const isComponentMounted = useRef();

  // Widget event handlers
  function onModuleLoaded(eventData) {
    console.warn('[Selphi] onModuleLoaded');
    console.log(eventData);
  }

  function onStabilizing(stabilizingResult) {
    console.warn('[Selphi] onStabilizing');
    console.log(stabilizingResult);
  }

  function onExtractionFinish(extractionResult) {
    console.warn('[Selphi] onExtractionFinish');
    facialExtractor = extractionResult.detail.bestImageCropped.src;
    facialExtractor = facialExtractor.slice(23);

    if (generateFlow) {
      enableGenerateQr();
    } else {
      authenticate();
    }
  }

  function onUserCancel() {
    console.warn('[Selphi] onUserCancel');
  }

  function onExceptionCaptured(exceptionResult) {
    console.warn('[Selphi] onExceptionCaptured');
    console.log(`exceptionType: ${exceptionResult.detail.exceptionType}`);
    console.log(`exceptionMessage: ${exceptionResult.detail.message}`);
    console.log(exceptionResult);
  }

  function onLivenessError(livenessErrorResult) {
    console.warn('[Selphi] onLivenessError');
    console.log(livenessErrorResult);

    switch (livenessErrorResult.detail.livenessErrorType) {
      case FPhi.Selphi.LivenessDiagnostic.Unsuccess:
        console.log('[Selphi] Liveness error: Blink or movement not detected');
        break;
      case FPhi.Selphi.LivenessDiagnostic.UnsuccessLowPerformance:
        console.log('[Selphi] Liveness error: Low performance');
        break;
      case FPhi.Selphi.LivenessDiagnostic.UnsuccessGlasses:
        console.log('[Selphi] Liveness error: Glasses detected');
        break;
      case FPhi.Selphi.LivenessDiagnostic.UnsuccessLight:
        console.log('[Selphi] Liveness error: Bad lighting conditions');
        break;
      case FPhi.Selphi.LivenessDiagnostic.UnsuccessNoMovement:
        console.log('[Selphi] Liveness error: No movement');
        break;
      case FPhi.Selphi.LivenessDiagnostic.UnsuccessWrongDirection:
        console.log('[Selphi] Liveness error: Wrong direction');
        break;
      case FPhi.Selphi.LivenessDiagnostic.UnsuccessTooFar:
        console.log('[Selphi] Liveness error: Face too far');
        break;
      default:
        console.log('[Selphi] Liveness error');
        break;
    }
  }

  function onLivenessErrorButtonClick() {
    console.warn('[Selphi] onLivenessErrorButtonClick');
  }

  function onExtractionTimeout(extractionTimeoutResult) {
    console.warn('[Selphi] onExtractionTimeout');
    console.log(extractionTimeoutResult);
  }

  function onTimeoutErrorButtonClick() {
    console.warn('[Selphi] onTimeoutErrorButtonClick');
  }

  function onTrackStatus(eventData) {
    const trackStatusCode = Object.entries(FPhi.Selphi.TrackStatus)
      .find((e) => e[1] === eventData.detail.code);
    console.warn(`[Selphi] onTrackStatus (Code: ${trackStatusCode[1]} - ${trackStatusCode[0]}, Timestamp: ${eventData.detail.timeStamp}`);
    console.log(eventData);
  }

  // Link events with effect
  useEffect(() => {
    isComponentMounted.current = true;
    const node = widgetRef.current;

    node.addWidgetEventListener('onModuleLoaded', onModuleLoaded);
    node.addWidgetEventListener('onStabilizing', onStabilizing);
    node.addWidgetEventListener('onExtractionFinish', onExtractionFinish);
    node.addWidgetEventListener('onUserCancel', onUserCancel);
    node.addWidgetEventListener('onExceptionCaptured', onExceptionCaptured);
    node.addWidgetEventListener('onLivenessError', onLivenessError);
    node.addWidgetEventListener('onLivenessErrorButtonClick', onLivenessErrorButtonClick);
    node.addWidgetEventListener('onExtractionTimeout', onExtractionTimeout);
    node.addWidgetEventListener('onTimeoutErrorButtonClick', onTimeoutErrorButtonClick);
    node.addWidgetEventListener('onTrackStatus', onTrackStatus);
  });

  return (
    <div className="p-3">
      <div className="m-3 row div-selphi d-flex justify-content-center">
        {/* Widget web SelphID */}
        <div className="col-12 col-md-9 text-center " style={{ minHeight: 550 }}>
          <facephi-selphi
            className=""
            ref={widgetRef}
            bundlePath="../../assets/selphi"
            language="en"
            style={{
              width: '100%',
              height: '90%',
            }}
            livenessMode={widgetLivenessMode}
            cameraWidth={widgetCameraWidth}
            cameraHeight={widgetCameraHeight}
            cameraType={widgetCameraType}
            interactible={widgetInteractible}
            tutorial={widgetTutorial}
            stabilizationStage={widgetStabilizationStage}
            logImages
            cropFactor={1.7}
            videoRecord={widgetVideoRecord}
            videoRecordType={FPhi.Selphi.RecorderType.Remote}
            videoRecordScale={widgetCameraWidth < 1280 ? 1 : 0.5}
            showLog={widgetShowLog}
            debugMode={widgetDebugMode}
          />
        </div>
      </div>
    </div>
  );
}

SelphiWidget.propTypes = {
  generateFlow: PropTypes.bool,
  qrCode: PropTypes.string,
  generateRequest: {
    facialDocument: PropTypes.string,
    facialImage: PropTypes.string,
  },
};

SelphiWidget.defaultProps = {
  generateFlow: true,
  qrCode: '',
  generateRequest: {
    facialDocument: '',
    facialImage: '',
  },
};

export default SelphiWidget;
