/* eslint-disable import/no-cycle */
import React from 'react';
import MainCards from '../MainCards/MainCards';
import widget from '../../index';
import iconStop from '../../img/icon-stop.png';
import './Footer.css';

function Footer() {
  function handleStopBtn() {
    widget.render(<MainCards />);
    document.querySelector('.btnStopProcess').classList.add('hidden');
  }

  return (
    <div className="div-stop">
      <button type="button" id="btn-stop" onClick={handleStopBtn} className="btnStopProcess btn hidden">
        <img src={iconStop} alt="stop icon" />
        {' '}
        Stop process
      </button>
      <footer className="text-white p-3 div-footer">
        <small>2022 ® FacePhi. All rights reserved. QR Code Demo - 1.2.0 version</small>
      </footer>
    </div>
  );
}

export default Footer;
